
import { reactive, defineComponent, nextTick, onMounted, toRefs } from 'vue'
import { ArticleModel } from '@/model/articleModel'

import Sortable from 'sortablejs'
import { getArticles } from '@/apis/articles'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  setup() {
    let sortable: Sortable | null = null
    const { t } = useI18n()
    const dataMap = reactive({
      list: Array<ArticleModel>(),
      listLoading: true,
      total: 0,
      oldList: [0],
      newList: [0],
      listQuery: {
        page: 1,
        limit: 100
      },
      async getList() {
        dataMap.listLoading = true
        const data = await getArticles(this.listQuery)
        dataMap.list = data?.data.items ?? []
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
        dataMap.total = data?.data.total ?? 0
        dataMap.oldList = dataMap.list.map((v) => v.id)
        dataMap.newList = this.oldList.slice()
        nextTick(() => {
          this.setSort()
        })
      },
      setSort() {
        const el = document.querySelectorAll(
          '.el-table__body-wrapper > table > tbody'
        )[0] as HTMLElement
        sortable = Sortable.create(el, {
          ghostClass: 'sortable-ghost', // Class name for the drop placeholder
          onEnd: (evt) => {
            if (
              typeof evt.oldIndex !== 'undefined' &&
              typeof evt.newIndex !== 'undefined'
            ) {
              const targetRow = this.list.splice(evt.oldIndex, 1)[0]
              this.list.splice(evt.newIndex, 0, targetRow)
              // for show the changes, you can delete in you code
              const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
              this.newList.splice(evt.newIndex, 0, tempIndex)
            }
          }
        })
      }
    })
    onMounted(() => {
      dataMap.getList()
    })
    return { t, sortable, ...toRefs(dataMap) }
  }
})
